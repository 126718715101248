<template>
    <div class="upload_wpr mb-3" :class="{ 'button-uploader': isButton }, uploadText">
        <label :for="uniqueId" :class="{ upload_area: !isButton, upload_btn: isButton, blank_img: !modelValue }" @click="showLibrary = true" v-bind="getRootProps()" v-if="initializedDragAndDrop">
            <input v-if="!showLibrary" v-bind="getInputProps({ ref: `file-chooser-single-${uniqueId}`, multiple: false })" :id="uniqueId" :ref="`file-chooser-single-${uniqueId}`" name="image" :status="status" hidden :accept="gifOnly ? 'image/gif' : 'image/*'">
            <template  v-if="modelValue && modelValue != 'null' && !isButton">
                <img :src="modelValue" alt="" class="uploaded_img">
                <span><img src="@/assets/images/image.svg" alt=""></span>
            </template>
            <template v-else>
                <div class="select_img" v-if="isAvatar">
                    <img src="@/assets/images/avatar-mask.png">
                </div>
                <div class="select_img" v-else-if="isButton">
                    <img src="@/assets/images/image.svg">
                </div>
                <img v-else src="@/assets/images/image2.png" class="empty_icon">
                <h4 v-if="!isButton">Click to Add {{ uploadText }}</h4>
                <p v-if="!isButton && imageType === 'custom-icon'">or drag and drop SVG</p>
                <p v-if="!isButton && imageType !== 'custom-icon'">or drag and drop SVG, PNG, JPG or GIF</p>
                <p class="info mt-3" v-if="size != '0x0' && !isButton && isInside">Recommended size is {{ size }}</p>
            </template>
        </label>
        <button type="button" class="image-remove-button" v-if="modelValue && validLink(modelValue) && !isButton" @click="removeImage">Remove</button>
        <p class="info mt-3" v-if="size != '0x0' && !isButton && !isInside">Recommended size is {{ size }}</p>
    </div>
    <div class="preview_area show_sidebar" v-if="showLibrary" :class="showLibrary ? `active ${classlist}`:`${classlist}`">
        <div class="content_area library">
            <button type="button" class="close-btn pointer"><i class="fas fa-times" @click.self="closeModal()"></i></button>
            <image-upload v-model="image" :crop="crop" :image-type="imageType" :handle-image="handleInsertImage" :ref="uniqueId" :show-library="true" :classlist="classlist" :gif-only="gifOnly" :is-free-images="isFreeImages" />
        </div>
        <div class="action_wpr">
            <button type="button" class="btn cancel_btn" @click.self="closeModal()">Cancel</button>
            <button v-if="showAction" type="button" class="btn save_btn ml-2" @click="handleInsertImage()">
                <template v-if="loader"><i class="fa fa-spin fa-spinner"></i>&nbsp; Uploading</template>
                <template v-else>Insert</template>
            </button>
        </div>
    </div>
    <cropper-component v-model="cropper" :handle-crop="handleCrop" :crop-type="imageType" :image="cropImage" :classlist="classlist"/>
</template>

<script>
    import ImageUpload from '@/components/image-library/ImageUpload'
    import CropperComponent from '@/components/image-library/Cropper'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'

    import { useDropzone } from 'vue3-dropzone'
    import { uuid } from 'vue-uuid'
    import { mapState, mapGetters, mapActions } from 'vuex'

    export default {
        name: 'Image Library',

        data () {
            return {
                image: '',
                uniqueId: 'library-'+uuid.v4(),
                showAction: true,
                showLibrary: false,
                size: '0x0',
                getRootProps: null,
                getInputProps: null,
                initializedDragAndDrop: false,
                cropper: false,
                cropImage: '',
                status: 0,
                loader: false,
            };
        },

        props: {
            modelValue: String,
            handleImage: Function,
            classlist: String,
            imageType: {
                type: String,
                default: 'all',
            },
            isAvatar: {
                type: Boolean,
                default: false,
            },
            isButton: {
                type: Boolean,
                default: false,
            },
            uploadText: {
                type: String,
                default: '',
            },
            isInside: {
                type: Boolean,
                default: false,
            },
            crop: {
                type: Boolean,
                default: true,
            },
            dataId: {
                type: Number,
                default: 0,
            },
            openExternalCropper: Function,
            gifOnly: {
                type: Boolean,
                default: false,
            },
            modalOpenHandler: Function,
            isFreeImages: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue'],

        components: {
            ImageUpload,
            CropperComponent,
        },

        watch: {
            modelValue (image) {
                const vm = this;

                vm.image = image;
            },

            imageType (type) {
                const vm = this;

                vm.initAspectRatio(type);
            },

            showLibrary (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');

                    if (vm.modalOpenHandler) {
                        vm.modalOpenHandler(vm.dataId, true);
                    }
                } else {
                    document.body.classList.remove('modal-open');

                    if (vm.modalOpenHandler) {
                        vm.modalOpenHandler(vm.dataId, false);
                    }
                }
            }
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
            }),

            ...mapGetters({
                getAspectRatio: 'imageLibraryModule/GET_CROPPER_ASPECT_RATIO_BY_TYPE',
            }),
        },

        mounted () {
            const vm = this;

            vm.initAspectRatio(vm.imageType);

            const { getRootProps, getInputProps  } = useDropzone({ onDrop: vm.dropzoneDrop });

            vm.getRootProps = getRootProps;
            vm.getInputProps = getInputProps;

            vm.initializedDragAndDrop = false;

            setTimeout(function () {
                vm.initializedDragAndDrop = true;
            }, 500);
        },

        methods: {
            ...mapActions({
                uploadImage: 'imageLibraryModule/uploadImage',
            }),

            closeModal (e) {
                const vm = this;

                setTimeout(function () {
                    vm.showLibrary = false;
                }, 100);
            },

            handleInsertImage () {
                const vm = this;

                vm.$emit('update:modelValue', vm.image);

                if (vm.openExternalCropper) {
                    vm.openExternalCropper();
                }

                if (vm.handleImage) {
                    vm.handleImage(vm.image, vm.dataId);
                }

                setTimeout(function () {
                    vm.showLibrary = false;
                }, 100);
            },

            removeImage () {
                const vm = this;
                vm.status = 0;
                vm.$emit('update:modelValue', '');
            },

            initAspectRatio (type) {
                const vm = this;
                const ratio = vm.getAspectRatio(type);

                vm.size = ratio.size;
            },

            validLink (str) {
                const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

                return !!pattern.test(str);
            },

            dropzoneDrop (acceptFiles) {
                const vm = this;

                vm.handleFileUpload('image', acceptFiles);
            },

            handleFileUpload(fieldName, fileList) {
                const vm = this;

                if (vm.crop) {
                    let file = fileList[0];

                    if (!file.type.includes('image/')) {
                        Toastr.error('Please select an image file');

                        return;
                    } else {
                        if (typeof FileReader === 'function') {
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                const ext = event.target.result.split(';')[0].split('/')[1];

                                if (ext == 'gif') {
                                    const options = Helper.fileCropWarning();

                                    options.preConfirm = function (confirm) {
                                        if (confirm) {
                                            vm.initImageCropper(event.target.result);
                                        } else {
                                            let location = `uploads/media/${vm.user.id}/${vm.size}`;

                                            const form = new FormData();
                                            form.append('image', Helper.base64toBlob(event.target.result, ext));
                                            form.append('size', vm.size);
                                            form.append('type', vm.size);
                                            form.append('location', location);

                                            vm.loader = true;

                                            vm.uploadImage(form).then((resp) => {
                                                if (resp.data.status == 1) {
                                                    const file = resp.data.response;
                                                    vm.status = 1;
                                                    vm.$emit('update:modelValue', file);
                                                    Toastr.success('Image successfully uploaded!');
                                                    document.getElementById(vm.uniqueId).value = '';

                                                    if (vm.handleImage) {
                                                        vm.handleImage()
                                                    }
                                                }
                                                vm.loader = false;
                                            }).catch((error) => {
                                                vm.loader = false;
                                                Toastr.error('Unable to upload image, Please try to reduce the cropped image and upload again!');
                                            });
                                        }
                                    }

                                    Swal.fire(options).then((result) => {
                                        if (result.isDismissed) {
                                            let location = `uploads/media/${vm.user.id}/${vm.size}`;

                                            const form = new FormData();
                                            form.append('image', Helper.base64toBlob(event.target.result, ext));
                                            form.append('size', vm.size);
                                            form.append('type', vm.size);
                                            form.append('location', location);

                                            vm.loader = true;

                                            vm.uploadImage(form).then((resp) => {
                                                if (resp.data.status == 1) {
                                                    const file = resp.data.response;
                                                    vm.status = 1;
                                                    vm.$emit('update:modelValue', file);
                                                    Toastr.success('Image successfully uploaded!');
                                                    document.getElementById(vm.uniqueId).value = '';

                                                    if (vm.handleImage) {
                                                        vm.handleImage()
                                                    }
                                                }

                                                vm.loader = false;
                                            }).catch((error) => {
                                                vm.loader = false;
                                                Toastr.error('Unable to upload image, Please try to reduce the cropped image and upload again!');
                                            });
                                        }
                                    });
                                } else {
                                    vm.initImageCropper(event.target.result);
                                }
                            };

                            reader.readAsDataURL(file);
                        }
                    }
                } else {
                    const formData = new FormData();
                    let location = `uploads/media/${vm.user.id}/${vm.size}`;

                    if (!fileList.length) return;

                    Array.from(Array(fileList.length).keys()).map( x => {
                        formData.append(fieldName, fileList[x], fileList[x].name);
                    });
                    formData.append('type', vm.type);
                    formData.append('size', vm.type);
                    formData.append('location', location);

                    if (vm.user.id) {
                        vm.loader = true;

                        vm.uploadImage(formData).then((resp) => {
                            vm.status = 1;
                            vm.$emit('update:modelValue', resp.data.response);
                            Toastr.success('Success', 'Image successfully uploaded!');
                            document.getElementById(vm.uniqueId).value = '';

                            if (vm.handleImage) {
                                vm.handleImage()
                            }

                            vm.loader = false;
                        });
                    }
                }
            },

            initImageCropper (file) {
                const vm = this;

                vm.cropImage = file;
                vm.cropper = true;
            },

            handleCrop (file) {
                const vm = this;
                vm.status = 1;
                vm.$emit('update:modelValue', file);
                vm.tab = 1;

                if (document.getElementById(vm.uniqueId)) {
                    document.getElementById(vm.uniqueId).value = '';
                }

                if (vm.handleImage) {
                    vm.handleImage()
                }
            },
        }
    }
</script>

<style scoped>
    .global_setting .content_area, .content_area {
        padding: 40px 40px 20px 40px;
        flex-grow: 1;
    }

    .action_wpr {
        padding: 20px 40px;
        border-top: 1px solid #e9e9e9;
        margin: auto 0 0 0;
        z-index: 1;
        background: #fff;
    }

    .close-btn {
        right: 15px;
        left: auto;
        background: rgba(50,55,59,.6);
        color: #fff;
        height: 20px;
        width: 20px;
        font-size: 12px;
        position: absolute;
        right: 15px;
        top: 15px;
        border-radius: 50%;
    }

    .preview_area.show_sidebar {
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
    }

    :deep(.library .upload_area) {
        height: 400px;
    }

    .upload_wpr .upload_area .uploaded_img {
        height: 100%;
        width: auto;
        /* left: 50%;
        transform: translateX(-50%); */
    }

    .upload_wpr .upload_area {
        /* border: 1px dashed #95bfff; */
        background: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .color_container .upload_wpr .upload_area,
    .cover_type .upload_wpr .upload_area{
        background: #f9f9f9;
    }
    .upload_wpr .upload_area.blank_img img,
    .upload_wpr .upload_area img.empty_icon{
        max-width: 50px;
    }

    .upload_area h4, .dz-message h4 {
        font-size: 16px;
        line-height: 22px;
        color: #575757;
        font-weight: 400;
        margin: 14px 0 0;
        display: block!important;
    }

    .upload_area p, .dz-message p {
        font-size: 14px;
        line-height: 20px;
        color: #787878;
        font-weight: 300;
    }

    .upload_wpr .upload_area .uploaded_img {
        object-fit: contain;
    }

    .upload_image.image_create .upload_wpr {
        height: 100%;
    }

    .modal_container .modal_body .upload_image.image_create label img.uploaded_img {
        max-width: 100%;
        height: 60%;
    }

    .upload_image label {
        /* border: 1.2px dashed #95bfff; */
        background: #fafcfd;
        /* border-radius: 4px; */
        display: block;
        padding: 25px 25px 15px 25px;
    }

    .upload_image .upload_wpr {
        width: 100%;
        height: 75%;
    }

    .modal.add_step > .modal_container .modal_body .upload_image > .upload_wpr label img.uploaded_img {
        max-width: 195px;
        height: 90%;
    }
    @media(max-width:1399px){
        .action_wpr {
            padding: 25px 40px;
        }
    }
    @media(max-width:991px){
        .action_wpr {
            padding: 25px 40px;
        }
    }
    @media(max-width:767px){
        .action_wpr {
            padding: 15px 35px;
        }
    }
    @media(max-width:499px){
        .action_wpr {
            padding: 15px 20px;
        }
    }
</style>
